@import url("https://fonts.googleapis.com/css?family=Lato:400");



/* Components */
/* html {
  box-sizing: border-box;
  background-color: #ffffff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.invisible {
  opacity: 0;
  transition: 0.7s;
} */

/* .fakefield {
  display: none;
}

.left-align {
  text-align: left;
}

.center,
center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.stress {
  color: #111;
}

img,
svg {
  max-width: 100%;
} */

/* .container {
  width: 88%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
}

.row {
  display: block;
  width: 100%;
  clear: both;
} */

/* .half {
  width: 50%;
  overflow: auto;
  float: left;
}

.text-container {
  max-width: 640px;
}
.text-container--center {
  margin-left: auto;
  margin-right: auto;
}

.margin-right {
  margin-right: 8pt;
} */

html {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #666;
  line-height: 1.7;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "liga" on;
}

/* p {
  margin-bottom: 1.3em;
}

h1,
h2,
h3,
h4 {
  margin: 1.414em 0 0.5em;
  line-height: 1.35;
  color: #111;
  font-weight: 400;
}

h5,
h6 {
  margin: 1.414em 0 0.5em;
  line-height: 1.4;
  color: #666;
  font-weight: 400;
}

h1 {
  margin-top: 0;
  font-size: 2.074em;
}

h2 {
  font-size: 1.728em;
}

h3 {
  font-size: 1.44em;
}

h4 {
  font-size: 1.2em;
}

small,
h6 {
  font-size: 0.833em;
  margin: 0.25em 0;
} */

pre {
  font-family: monospace, monospace;
  font-size: 0.85rem;
  color: #111;
  width: min-content;
  background: #f7f7f7;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  padding: 8pt 16pt;
  width: 100%;
  overflow: auto;
  border: 1px solid #eee;
}

a {
  outline: 0;
  text-decoration: none;
  transition: 0.7s;
  color: inherit;
}

.link {
  color: #6c63ff;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .link:hover {
    color: #463aff;
  }
}

button,
.button {
  display: inline-block;
  padding: 6pt 20pt;
  line-height: 1.7;
  border: 1px solid #6c63ff;
  color: #6c63ff;
  font-weight: 400;
  transition: 0.7s;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border-radius: 30px;
  margin-bottom: 1.3em;
  transition: 0.7s;
  text-align: center;
  text-decoration: none;
}
button:hover,
button:focus,
.button:hover,
.button:focus {
  outline: 0;
}
@media (min-width: 992px) {
  button:hover,
  .button:hover {
    border-color: #463aff;
    color: #463aff;
  }
}

.button__primary {
  background-color: #6c63ff;
  border-color: #6c63ff;
  color: #fff;
}
@media (min-width: 992px) {
  .button__primary:hover {
    background-color: #463aff;
    border-color: #463aff;
    color: #fff;
  }
}

.button__accent {
  background-color: #06d19c;
  border-color: #06d19c;
  color: #fff;
}
@media (min-width: 992px) {
  .button__accent:hover {
    background-color: #05bd8d;
    border-color: #05bd8d;
    color: #fff;
  }
}

.button__delete {
  background-color: tomato;
  border-color: tomato;
  color: #fff;
}
@media (min-width: 992px) {
  .button__delete:hover {
    background-color: #ff5233;
    border-color: #ff5233;
    color: #fff;
  }
}

.button:disabled {
  opacity: 0.25;
  cursor: default;
  pointer-events: none;
}

.button--fixed {
  width: 160px;
}

.button--fixed2 {
  width: 220px;
}

form {
  margin-bottom: 1.5em;
}
form .double {
  -js-display: flex;
  display: flex;
  justify-content: space-between;
}
form .double .half {
  max-width: 48%;
}

.form {
  margin-left: auto;
  margin-right: auto;
}

label {
  margin-bottom: 2pt;
  display: block;
  text-align: left;
  color: #666;
  font-size: 0.9em;
  padding-left: 10pt;
}

/* input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"] {
  appearance: none;
  border: 1px solid #eee;
  width: 100%;
  transition: 0.7s;
  color: #111;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 10pt 20pt;
  border-radius: 30px;
  background-color: #f7f7f7;
  margin-bottom: 1.4em;
  display: block;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus {
  border-color: rgba(108, 99, 255, 0.5);
  outline: 0;
  background-color: #ffffff;
} */

/* textarea {
  appearance: none;
  border: 1px solid #eee;
  width: 100%;
  transition: 0.7s;
  color: #111;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 12pt 24pt;
  border-radius: 30px;
  background-color: #f7f7f7;
  margin-bottom: 1.4em;
  display: block;
  resize: none;
  min-height: 8em;
  outline: 0;
}
textarea:focus {
  border-color: rgba(108, 99, 255, 0.5);
  outline: 0;
  background-color: #ffffff;
} */

/* select {
  border: 1px solid #eee;
  width: 100%;
  transition: 0.7s;
  color: #111;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 10pt 20pt;
  margin-bottom: 1.4em;
  display: block;
  border-radius: 30px;
  background-color: #f7f7f7;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10 fa-3x"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 0.8em;
} */




[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.landing__section {
  padding: 24pt 0;
}

.landing__section:nth-child(odd) {
  background-color: #f7f7f7;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.navbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #6c63ff;
  transition: 0.7s;
}
.navbar--nofixed {
  position: relative;
  z-index: 3;
}

.navbar__inner {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  color: #fff;
  transition: 0.7s;
}

.navbar__logo {
  font-size: 1.325rem;
  transition: 0;
  color: #fff;
  text-decoration: none;
}

.navbar__menu {
  display: none;
}
.navbar__menu--noMob {
  display: block;
}
@media (min-width: 992px) {
  .navbar__menu {
    display: block;
  }
}
.navbar__menu li {
  margin-left: 4pt;
  backface-visibility: hidden;
  display: inline-block;
}
@media (min-width: 992px) {
  .navbar__menu li {
    margin-left: 16pt;
  }
}
.navbar__menu a {
  color: #fff;
}
@media (min-width: 992px) {
  .navbar__menu a {
    opacity: 0.75;
  }
  .navbar__menu a:hover {
    opacity: 1;
  }
  .navbar__menu a:after {
    display: block;
    content: "";
    border-bottom: solid 1px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .navbar__menu a:hover:after {
    transform: scaleX(1);
  }
}

.navbar__menu-mob {
  display: block;
}
.navbar__menu-mob--noMob {
  display: none;
}
@media (min-width: 992px) {
  .navbar__menu-mob {
    display: none;
  }
}

/*--- Extended and transparent version ---*/
.navbar--extended {
  background-color: transparent;
}
.navbar--extended .navbar__inner {
  height: 60px;
  color: #ffffff;
}

/*! responsive-nav.js 1.0.39 by @viljamis */
.nav__mobile ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style: none;
  background-color: #333c44;
  color: #fff;
}

.nav__mobile li {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em 0;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav__mobile li:last-child {
  border-bottom: none;
}

.js .nav__mobile {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav__mobile.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@media (min-width: 992px) {
  .js .nav__mobile {
    position: relative;
  }
  .js .nav__mobile.closed {
    max-height: none;
  }
  .nav-toggle {
    display: none;
  }
}

#toggle svg {
  height: 1.2em;
  width: 1.2em;
  vertical-align: middle;
}

.hero {
  position: relative;
  width: 100%;
  background-image: url("./images/nft_bg_image.jpg");
  box-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ffffff;
}
.hero__inner {
  position: relative;
  z-index: 3;
}

.hero__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.96;
  z-index: 1;
  background-color: #6c63ff;
}

.hero__overlay--gradient {
  background: linear-gradient(to right, #6c63ff, #4641ff);
}

.hero__overlay--gradientVertical {
  background: linear-gradient(to bottom, #6c63ff, #4641ff);
}

.hero__mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080"><defs><linearGradient id="06714b97-aec8-4b7e-bf3d-1e20f95f612e" y1="809.5" x2="958" y2="809.5" gradientTransform="matrix(1, 0, 0, -1, 0, 1082)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="1" stop-color="#fff" stop-opacity="0.05"/></linearGradient></defs><title>hero</title><rect id="0567095b-2cf7-4407-b8ab-ec2abe52d830" data-name="&lt;Path&gt;" y="545" width="1920" height="535" fill="#fff" opacity="0.05" style="isolation:isolate"/><polygon id="82ca9a61-a0a1-49cc-9805-a3533c693bad" data-name="&lt;Path&gt;" points="958 545 0 545 0 0 770.96 0 958 545" fill="url(#06714b97-aec8-4b7e-bf3d-1e20f95f612e)"/></svg>');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.66;
  z-index: 2;
}

.hero__content {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  padding: calc(92px) 0;
  overflow: auto;
}
.hero__content__inner {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

/*--- CONTENT STYLING ---*/
.hero__title {
  color: inherit;
  margin-bottom: 1.25em;
}

.hero__text {
  opacity: 0.85;
  line-height: 1.7;
  text-align: left;
  font-size: 1.1em;
}

.hero .button {
  margin: 12pt 8pt;
}

.hero__button {
  color: #ffffff;
  border-color: #ffffff;
}
@media (min-width: 992px) {
  .hero__button:hover {
    color: #e3e3e3;
    border-color: #e3e3e3;
  }
}

.hero__sub {
  -js-display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.hero__sub__down {
  width: 1rem;
  height: 1rem;
  color: #666;
  transition: 0.7s;
  cursor: pointer;
}
@media (min-width: 992px) {
  .hero__sub__down:hover {
    color: #6c63ff;
  }
}

#scrollToNext {
  transition: 0.7s;
}

/*--- MODIFICATIONS ---*/
.hero--full .hero__content {
  min-height: 100vh;
}

.hero--full + .hero__sub {
  display: none;
}

.steps {
  text-align: center;
  padding: 16pt 0;
}

@media (min-width: 992px) {
  .steps__inner {
    -js-display: flex;
    display: flex;
    justify-content: space-around;
  }
}

.step {
  padding: 16pt 0;
  text-align: center;
  width: 100%;
  border-top: 1px solid #eee;
  overflow: auto;
}
.step:first-child {
  border-top: none;
}
@media (min-width: 992px) {
  .step {
    border-top: none;
    width: auto;
  }
}

.step__media {
  margin: 16pt 0;
}
@media (min-width: 992px) {
  .step__media {
    margin: 32pt 0;
  }
}

.step__image {
  max-height: 100px;
  max-width: 222px;
  margin-top: 8pt;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
@media (min-width: 600px) {
  .step__image {
    max-width: none;
  }
}

.step__text {
  max-width: 36ch;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.expanded {
  padding: 72pt 0;
}

.expanded__inner {
  -js-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 992px) {
  .expanded__inner {
    flex-direction: row;
  }
}

.expanded__media {
  order: 1;
  text-align: center;
}
@media (min-width: 992px) {
  .expanded__media {
    width: 50%;
    flex-shrink: 0;
    order: unset;
  }
}

.expanded__content {
  order: 2;
}
@media (min-width: 992px) {
  .expanded__content {
    padding: 0 16pt;
    width: 50%;
    flex-shrink: 0;
    order: unset;
  }
}

.expanded:nth-child(even) .expanded__media {
  order: 1;
}
@media (min-width: 992px) {
  .expanded:nth-child(even) .expanded__media {
    order: 1;
  }
}

/* Actual content */
.expanded__image {
  width: 90%;
  max-width: 220px;
  padding: 16pt 0;
}
@media (min-width: 992px) {
  .expanded__image {
    max-width: 280px;
  }
}

.expanded__title {
  max-width: 550px;
}

.expanded__text {
  max-width: 60ch;
}

.cta {
  background: linear-gradient(to right, #6c63ff, #4641ff);
  color: #fff;
  padding: 48pt 0;
  text-align: center;
}

.cta--reverse {
  background: linear-gradient(to left, #6c63ff, #4641ff);
}

.cta--plain {
  background: #6c63ff;
}

.cta--secondary {
  background: #4641ff;
}

.cta__title {
  color: inherit;
}

.cta__sub {
  opacity: 0.85;
  max-width: 58ch;
  margin: 16pt auto 24pt auto;
}
.cta__sub--center {
  text-align: center;
}

/* .footer {
  background-color: #ffffff;
  padding: 36pt 0;
}

.footer__inner {
  -js-display: flex;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 600px) {
  .footer__inner {
    flex-direction: row;
  }
}

.footer__textLogo {
  flex-shrink: 0;
  padding-right: 16pt;
  display: block;
  cursor: pointer;
  color: #6c63ff;
  font-size: 1.225rem;
  min-width: 33%;
  margin-bottom: 1.5rem;
}
@media (min-width: 600px) {
  .footer__textLogo {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer__textLogo {
    min-width: 25%;
    font-size: 1.325rem;
  }
}

.footer__data {
  -js-display: flex;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding-top: 0.25rem;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 0.9rem;
}
@media (min-width: 992px) {
  .footer__data {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.footer__data__item {
  padding-bottom: 0.5rem;
  display: inline-block;
  margin-bottom: 8pt;
}

.footer__link {
  color: #6c63ff;
  border-bottom: 1px dotted #6c63ff;
  padding-bottom: 1pt;
  transition: 0.7s;
}
@media (min-width: 992px) {
  .footer__link:hover {
    color: #fafafa;
    border-color: #fafafa;
  }
}

.footer__row {
  margin-bottom: 8pt;
} */

/*--- Modifiers---*/
/* .footer--dark {
  background-color: #333c44;
}
.footer--dark .footer__textLogo {
  color: #fff;
}
.footer--dark .footer__inner {
  color: #d9d9d9;
}
.footer--dark .footer__link {
  color: #d9d9d9;
  border-color: #d9d9d9;
}
@media (min-width: 992px) {
  .footer--dark .footer__link:hover {
    color: #fff;
    border-color: #fff;
  }
} */

/* .auth {
  padding-top: 102.66667px;
}

.auth__inner {
  min-height: calc(100vh - 188px);
  -js-display: flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 992px) {
  .auth__inner {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .auth__media {
    width: 50%;
    flex-shrink: 0;
  }
}

.auth__media img,
.auth__media svg {
  max-height: 150px;
  max-width: 90%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 600px) {
  .auth__media img,
  .auth__media svg {
    max-height: 300px;
  }
}
@media (min-width: 992px) {
  .auth__media img,
  .auth__media svg {
    max-height: 400px;
  }
}

.auth__auth {
  text-align: center;
}
@media (min-width: 992px) {
  .auth__auth {
    width: 50%;
    flex-shrink: 0;
  }
}

.auth__title {
  font-size: 1.728em;
}

.page__header {
  position: relative;
  color: #ffffff;
  box-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
  width: 100%;
}

.page__header__inner {
  padding: 66px 0 0 0;
  position: relative;
  z-index: 3;
}

.page__header__content {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32pt 0;
  overflow: auto;
}
.page__header__content__inner {
  width: 100%;
  max-width: 66ch;
  text-align: center;
} */

/*--- CONTENT STYLING ---*/
/* .page__header__title {
  color: inherit;
  margin-bottom: 1.25em;
}

.page__header__text {
  opacity: 0.85;
  line-height: 1.7;
  text-align: left;
}

.page {
  padding: 24pt 0;
}

@media (min-width: 992px) {
  .page__inner {
    -js-display: flex;
    display: flex;
  }
}

.page__menu {
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .page__menu {
    display: block;
    width: 300px;
    flex-shrink: 0;
  }
}

.page__main {
  flex-grow: 1;
}

.page__main__title {
  margin-top: 0.75em;
}

.page__image {
  max-width: 100%;
}

.app__header {
  position: relative;
  color: #ffffff;
  box-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
  width: 100%;
}

.app__header__inner {
  padding: 66px 0 0 0;
  position: relative;
  z-index: 3;
}

.app__header__content {
  -js-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32pt 0;
  overflow: auto;
}
.app__header__content__inner {
  width: 100%;
  max-width: 66ch;
  text-align: center;
} */

/*--- CONTENT STYLING ---*/
/* .app__header__title {
  color: inherit;
  margin-bottom: 1.25em;
}

.app__header__text {
  opacity: 0.85;
  line-height: 1.7;
  text-align: left;
}

.app {
  padding: calc(81.33333px) 0;
}

@media (min-width: 992px) {
  .app__inner {
    -js-display: flex;
    display: flex;
  }
}

.app__menu {
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .app__menu {
    display: block;
    width: 300px;
    flex-shrink: 0;
  }
}

.app__main {
  flex-grow: 1;
}

.app__main__title {
  margin-top: 0.75em;
}

.app__image {
  max-width: 100%;
} */

/* Vertical menu */
/* .vMenu {
  padding: 0;
  list-style-type: none;
  -js-display: flex;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
@media (min-width: 992px) {
  .vMenu {
    display: inline-block;
    border-bottom: none;
  }
}
.vMenu li {
  display: inline-block;
  margin-bottom: 1em;
  width: 48%;
  padding-left: 16pt;
}
@media (min-width: 992px) {
  .vMenu li {
    padding-left: 0;
    display: block;
    width: auto;
  }
}
.vMenu a {
  display: block;
}
@media (min-width: 992px) {
  .vMenu a {
    display: inline-block;
    opacity: 0.85;
  }
  .vMenu a:hover {
    opacity: 1;
    color: #6c63ff;
  }
  .vMenu a:after {
    display: block;
    content: "";
    border-bottom: 1px solid #6c63ff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .vMenu a:hover:after {
    transform: scaleX(1);
  }
}
.vMenu > ul {
  margin-top: -0.65em;
  padding-left: 1.5em;
  margin-bottom: 0.75em;
}
.vMenu > ul li {
  opacity: 0.85;
  font-size: 0.95em;
  margin-bottom: 0.25em;
}
.vMenu > ul li:before {
  content: "-";
  margin-right: 0.5em;
}

.vMenu--active {
  color: #6c63ff;
}
.vMenu--active:before {
  content: "\2014";
  margin-right: 0.333em;
}
@media (min-width: 992px) {
  .vMenu--active {
    opacity: 1;
    color: #6c63ff;
  }
  .vMenu--active:after {
    display: block;
    content: "";
    border-bottom: 1px solid #6c63ff;
    transform: none;
    transition: none;
  }
} */
