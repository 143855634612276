.section4 {
    padding: 50px;
    background-color: #f3f4f8;
    font-family: 'Maven Pro', sans-serif;
}

@media screen and (max-width: 940px) {
    .section4 {
        padding: 30px 10px;
    }
}

.section4 .feature-row {
    margin-top: 30px;
}

.section4 .feature-row .feature-col {
    border-radius: 10px;
    text-align: center;
    background-color: #FFF;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 3px 0 rgba(218, 218, 253, 0.35), 0 0px 3px 0 rgba(206, 206, 238, 0.35);
}

.section4 .feature-row .feature-col img {
    margin-bottom: 15px;
    width: 150px;
}

.section4 .feature-row .feature-col i {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #28a745;
}

/* .section4 .feature-row .feature-col:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
} */

.section4 .feature-row .feature-col h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #3dc3ff;
}

.section4 .feature-row .feature-col p {
    font-size: 1.2rem;
}

.section4 .feature-row .feature-col button {
    font-weight: 500;
    background-color: #cfcfcf;
    border-color: #cfcfcf;
    color: #000;
}

.section4 .feature-row .feature-col button:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: #ffffff;
    border-color: #000000;
}