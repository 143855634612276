.usecases {
    padding: 50px;
    background-color: #f3f4f8;
    font-family: 'Maven Pro', sans-serif;
}

@media screen and (max-width: 940px) {
    .usecases {
        padding: 30px 10px;
    }
}

.usecases h3 {
    text-align: center;
}

.usecases h3 span {
    font-weight: bold;
    color: #3dc3ff;
}

.usecases .usecase-row {
    margin-top: 30px;
}

.usecases .usecase-row .usecase-col {
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
}

.usecases .usecase-row .usecase-col img {
    margin-bottom: 15px;
    width: 140px;
}

.usecases .usecase-row .usecase-col:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.usecases .usecase-row .usecase-col h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 12px;
}

.usecases p {
    text-align: center;
    font-size: 1.5rem;
}