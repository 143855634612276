.banner-container {
    background-color: #f3f4f8;
    min-height: 100vh;
    display: grid;
    place-items: center;
}

.banner-container .banner-row .banner-txt {
    margin: auto;
}

.banner-container .banner-row .banner-txt h1 {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 2.8rem;
    font-family: 'Maven Pro', sans-serif;
}

.banner-container .banner-row .banner-txt p {
    margin-bottom: 20px;
    font-size: 0.9rem;
    font-weight: 500;
}

.banner-container .banner-row .btn-row {
    margin-top: 50px;
}

.banner-container .banner-row .btn-row .btn {
    width: 200px;
    margin-right: 20px;
}

@media only screen and (max-width: 767px) {
    .banner-container .banner-row .btn-row .btn {
        width: 100%;
        margin-bottom: 5px;
    }

    .banner-container .banner-row .banner-txt h1 {
        font-size: xx-large;
    }
}

.clr:hover {
    background-color: #3dc3ff !important;
    color: #000000 !important;
}

.banner-container .banner-img {
    text-align: center;
}

.banner-container .banner-img img {
    max-width: 400px;
}

@media only screen and (max-width: 767px) {
    .banner-container .banner-img img {
        width: 100%;
        margin-top: 50px;
    }
}