.features {
    padding: 50px;
    background-color: #f3f4f8;
    font-family: 'Maven Pro', sans-serif;
}

@media screen and (max-width: 940px) {
    .features {
        padding: 30px 10px;
    }
}

.features h3 {
    text-align: center;
}

.features h3 span {
    font-weight: bold;
    color: #3dc3ff;
}

.features .feature-row {
    margin-top: 30px;
}

.features .feature-row .feature-col {
    border-radius: 10px;
    text-align: center;
    background-color: #FFF;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 3px 0 rgba(218, 218, 253, 0.35), 0 0px 3px 0 rgba(206, 206, 238, 0.35);
}

.features .feature-row .feature-col img {
    margin-bottom: 15px;
    width: 100px;
}

.features .feature-row .feature-col i {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #28a745;
}

.features .feature-row .feature-col:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.features .feature-row .feature-col h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 12px;
}

.features .feature-row .feature-col p {
    font-size: 0.9rem;
}

/* 
.more-features {
    padding: 50px;
    background-color: #fff;
}

@media screen and (max-width: 940px) {
    .more-features {
        padding: 30px 10px;
    }
}

.more-features .more-featur-ro {
    margin-top: 20px;
}

.more-features .more-featur-ro .more-feat {
    display: flex;
    margin-bottom: 30px;
}

.more-features .more-featur-ro .more-feat .icon {
    padding: 15px;
}

.more-features .more-featur-ro .more-feat .icon img {
    width: 110px;
}

.more-features .more-featur-ro .more-feat .detail {
    padding: 15px;
} */