@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');
body {
  font-family: 'Maven Pro', sans-serif;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 20px;
  margin: 0;
}

input:focus, input:active {
  box-shadow: none !important;
}

img {
  max-width: 100%;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

section {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 575.98px) {
  section {
    padding: 1.25rem 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  section {
    padding: 1.875rem 0;
  }
}

.container {
  max-width: 1170px;
}

.btn4 {
  width:"425px";
  background-color: "#3dc3ff";
  color: "black";
  border: 0;
}
.pic4 {
  width: 120px;
}

/* @media screen and (max-width: 575px) {
  .btn4{
    width:200px
  }
  .pic4 {
    margin-left:30%
  }
} */
@media screen and (max-width: 575px) {
  .container {
    padding: 0px 15px;
    max-width: 100%;
    width: 100%;
  }
}

.text-mute {
  color: #787a7d !important;
}

html {
  scroll-behavior: smooth;
}

.section-title {
  font-family: 'Maven Pro', sans-serif;
}
.section-title span {
  background-color: rgba(66, 82, 249, 0.2);
  padding: 6px 20px;
  border-radius: 130px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #4252f9;
}
.section-title h2 {
  font-weight: 600;
  margin: 15px 0px;
  padding: 20px 40px 70px;
}
.section-title p {
  max-width: 600px;
  margin: auto;
}

header {
  padding-top: 2px;
}
header .head-ro {
  padding: 3px;
}
header .head-ro .logo img {
  max-width: 100px;
  padding-top: 3px;
  font-family: 'Maven Pro', sans-serif;
}
header .head-ro .show {
  display: block !important;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav {
    display: none;
  }
}
header .head-ro .nav ul {
  width: 100%;
}
header .head-ro .nav ul li {
  float: left;
  padding: 10px;
}
@media only screen and (max-width: 860px) {
  header .head-ro .nav ul li {
    padding: 20px 12px;
  }
}
header .head-ro .nav ul li a {
  font-weight: 550;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
  header .head-ro .nav ul li {
    float: none;
    text-align: right;
  }
}
header .head-ro .nav ul .btnll {
  float: right;
}
header .head-ro .nav ul .btnll .btn {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav ul .btnll .btn {
    width: 100%;
  }
}

.scroll-to-fixed-fixed {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(26,27,40,0.8);
  backdrop-filter: blur(15px);
}

.head-cover {
  margin-top: 0;
  padding: 1px;
  background-color: #1A1B28;
}
.head-cover a{
  text-decoration: none !important;
}

.head-cover ul li a{
  text-decoration: none !important;
}

.small-menu {
  float: right;
  padding: 10px;
  padding-top: 16px;
  font-size: 1.4rem;
}
.small-menu i {
  color: #ffffff;
}


.page-nav {
  background-size: 100%;
  padding: 68px;
  text-align: center;
  padding-top: 60px;
  background-color: #f3f4f8;
}
.page-nav ul {
  float: none;
  margin: auto;
  width: auto;
  margin-top: 15px;
}
@media screen and (max-width: 992px) {
  .page-nav {
    background-size: auto;
  }
}
@media screen and (max-width: 767px) {
  .page-nav {
    padding-top: 100px;
  }
}
@media screen and (max-width: 356px) {
  .page-nav {
    padding-top: 200px;
  }
}
.page-nav h2 {
  font-size: 30px;
  width: 100%;
  font-weight: 600;
  color: #444;
}
@media screen and (max-width: 600px) {
  .page-nav h2 {
    font-size: 26px;
  }
}
.page-nav ul li {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-size: 0.93rem;
  font-weight: 600;
  color: #444;
}
.page-nav ul li i {
  width: 30px;
  text-align: center;
  color: #444;
}
.page-nav ul li a {
  color: #444;
  font-size: 0.93rem;
  font-weight: 600;
}

/* ===================================== Our Team Style  ================================== */
.our-team {
  padding: 50px;
}

@media screen and (max-width: 940px) {
  .our-team {
    padding: 50px 10px;
  }
}
.our-team .team-row {
  margin-top: 20px;
}

.our-team .team-row .single-usr {
  box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
  margin-bottom: 20px;
}

.our-team .team-row .single-usr img {
  width: 100%;
}

.our-team .team-row .single-usr .det-o {
  padding: 20px;
  background-color: #0363b0;
}

.our-team .team-row .single-usr .det-o h4 {
  color: #FFF;
  margin-bottom: 0px;
}

.our-team .team-row .single-usr .det-o i {
  color: #FFF;
}

.cont-row {
  margin-bottom: 20px;
}

.testimonial-container {
  padding-bottom: 100px;
  background-color: rgb(38,39,52);
  color: white;
  font-family: 'Maven Pro', sans-serif;
}

.testimonial-container .title {
  text-align: center;
  padding-bottom: 100px;
}

/* @media screen and (max-width: 940px) {
  .testimonial-container {
    padding: 50px 10px;
  }
} */
/* .testimonial-container .float-auto {
  float: none;
  margin: auto;
} */

.testimonial {
  padding: 80px 20px 10px;
  margin-bottom: 120px;
  min-height: 280px;
  border-radius: 5px;
  /* border: 1px solid #3dc3ff; */
  background-color: #1A1B28;
  position: relative;
}

.testimonial .pic {
  background-color:#1A1B28;
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: 5px solid #3dc3ff;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
}

.testimonial .pic img {
  width: 100%;
  height: auto;
}

.testimonial .description {
  font-size: 15px;
  color: white;
  line-height: 27px;
  text-align: center;
  margin: 0;
  position: relative;
}

.testimonial .description p{
  margin-top: 20px;
  color: #cfcfcf;
}


.testimonial .title {
  display: inline-table;
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
  background: #1A1B28;
  border: 1px solid #3dc3ff;
  font-size: 10px;
  font-weight: 700;
  color: #3dc3ff;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.testimonial .title:hover {
  background-color: #3dc3ff;
  color: black;
}

.testimonial .post {
  font-size: 15px;
  color: #3dc3ff;
}


@media only screen and (max-width: 479px) {
  .testimonial {
    height: fit-content;
  }

  .testimonial .description:before {
    top: -20px;
  }

  .testimonial .title {
    font-size: 12px;
  }

  .testimonial .post {
    font-size: 11px;
  }
}
.no-margin {
  margin: 0px;
}

/* =================== Blog Style Starts Here =================== */
.blog {
  padding: 50px;
  background-color: #ffffff;
}

@media screen and (max-width: 990px) {
  .blog {
    padding: 0px 10px;
    padding-top: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .blog .container {
    text-align: center;
    max-width: 100%;
  }
}
.blog .blog-singe {
  /* box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54); */
  border-radius: 2px;
  background-color: #FFF;
  margin-bottom: 30px;
}

.blog .blog-singe .blog-img-tab {
  background-color: rgb(26,27,40);
  padding: 10px;
}

.blog .blog-singe .blog-content-tab {
  padding: 15px;
  color: white;
  background-color: rgb(26,27,40);
}

.blog .blog-singe .blog-content-tab h2 {
  font-size: 19px;
}

.blog .blog-singe .blog-content-tab i {
  margin-right: 10px;
  font-size: 12px;
}

.blog .blog-singe .blog-content-tab i small {
  margin-left: 5px;
  font-size: 100%;
}

.blog .blog-singe .blog-content-tab .blog-desic {
  margin-top: 8px;
  font-size: 0.9rem;
  text-indent: 20px;
  margin-bottom: 10px;
}

.blog .blog-singe .blog-content-tab a {
  color: #007bfc;
  font-size: 12px;
  font-weight: 600;
}

.blog .blog-singe .blog-content-tab a i {
  margin-left: 15px;
  font-size: 13px;
}

.blog-row {
  margin-top: 20px;
}

/* ===================================== Footer Cover Style  ================================== */


.copy {
  padding: 20px;
  background-color: rgb(23, 24, 32);
  color: #FFF;
}

.copy a {
  color: #FFF;
}

.copy span {
  float: right;
}

.copy span i {
  margin-right: 20px;
}
@media screen and (max-width: 560px) {
  .copy {
    padding-bottom: 40px;
  }
}

.cop-ck {
  padding: 20px;
  background-color: #ffffffbd;
}
.cop-ck h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.modal-style {
  color:white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height:350px;
  border: 1px solid rgb(80, 80, 80);
  background-color: rgb(38,39,52);
  border-radius:30px;
  box-shadow: 24px;
  text-align:center;
  padding-bottom: 20px;
}
.modal-btn {
  padding: 10px;
  border: 0;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(38,39,52);
}
@media screen and (max-width: 670px ) {
  .modal-style {
    width: 300px;
    height: 400px;
  }
  .modal-btn {
    padding: 8px;
  }
}

/* innovative startups */

.startups {
  padding: 90px 5px 50px;
  margin: 50px 40px 70px;
  width: 300px;
  height: 300px;
  /* border: 1px solid #3dc3ff; */
  background-color: #1A1B28;
  box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.startups .pic2 {
  padding:10px;
  background-color:#ffffff;
  width: 100px;
  height: 100px;
  border-radius: 20%;
  border: 5px solid #3dc3ff;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.startups .description2 {
  font-size: 15px;
  color: white;
  line-height: 27px;
  text-align: center;
  margin: 0;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.startups .btn2 {
  display: inline-table;
  padding: 10px;
  margin: 0 auto;
  background: #1A1B28;
  border: 1px solid #3dc3ff;
  font-size: 10px;
  font-weight: 700;
  color: #3dc3ff;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.startups .btn2:hover {
  background-color: #3dc3ff;
  color: black;
}

/* explore startups */

.explore {
  padding: 140px 10px 50px;
  margin: 70px 50px 70px;
  width: 90%;
  background-color: #1A1B28;
  box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.explore .pic3 {
  padding:20px;
  background-color:#ffffff;
  width: 200px;
  height: 200px;
  border-radius: 20%;
  border: 5px solid #3dc3ff;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
}
.explore .email {
  font-size: small;
  color: rgb(173, 173, 173);
  float: right;
  overflow: hidden;
  margin: 10px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.explore .description3 {
  font-size: 15px;
  color: rgb(173, 173, 173);
  line-height: 27px;
  margin: 0;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}


@media screen and (max-width: 670px ){
  .explore {
    margin-top: 120px;
    width: 300px;
  }
  .explore .pic3 {
    width: 150px;
    height: 150px;
  }
}


